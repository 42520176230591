<script setup lang="ts">
import type { CartItem } from '@tn/shared';
import { useCart } from '~/features/cart';
import { useTokens } from '~/features/tokens';

const props = defineProps<{
  readOnly?: boolean;
  items?: CartItem[];
}>();

const { validTokenCount } = useTokens();
const { products, totalCost, isEmptyCart } = useCart();

const cartItems = computed(() => props.items || products.value);

const infoMessage = computed((): string => {
  if (totalCost.value === validTokenCount.value) {
    return 'You have reached the maximum of samples';
  }

  if (totalCost.value > validTokenCount.value) {
    return 'You have exceeded your token balance';
  }

  return `You can only order a maximum of ${validTokenCount.value} samples`;
});

const infoTextColor = computed(() => {
  return totalCost.value >= validTokenCount.value;
});

const headerIcon = computed(() => {
  if (totalCost.value < validTokenCount.value) {
    return 'i-heroicons-information-circle-solid';
  }

  if (totalCost.value === validTokenCount.value) {
    return 'i-heroicons-check';
  }

  return null;
});
</script>

<template>
  <div class="w-full rounded-xl border border-b-4 border-black bg-white">
    <header v-if="!readOnly">
      <div
        class="flex items-center rounded-t-xl px-4 py-2"
        :class="{
          'bg-blue-50': totalCost < validTokenCount,
          'bg-green-500': totalCost === validTokenCount,
          'bg-red-400': totalCost > validTokenCount,
          'text-white': infoTextColor,
        }"
      >
        <UIcon
          v-if="headerIcon"
          :name="headerIcon"
          class="mr-2 h-4 w-4"
          :class="{
            'text-blue-500': totalCost < validTokenCount,
            'text-white': totalCost >= validTokenCount,
          }"
        />
        <span class="text-xs">
          {{ infoMessage }}
        </span>
      </div>
    </header>
    <CartEmpty v-if="!readOnly && isEmptyCart" />
    <div v-else class="px-4">
      <div v-for="(item, index) in cartItems" :key="item.title">
        <CartItem
          :title="item.title"
          :image="item.image"
          :size="item.size"
          :product-id="item.productId"
          :brand-name="item.brandName"
          :brand-id="item.brandId"
          :read-only="readOnly"
        />
        <UDivider v-if="index !== totalCost - 1" />
      </div>
    </div>
    <footer v-if="!readOnly">
      <div class="bg-beige-600 flex justify-between rounded-b-lg px-4 py-2">
        <span>Tokens Available</span>
        <TnTokenIcon size="sm">
          {{ validTokenCount }}
        </TnTokenIcon>
      </div>
    </footer>
  </div>
</template>
